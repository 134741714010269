const pcHome = () => import('@/views/pc/home/index.vue'); // 首页
const pcAbout = () => import('@/views/pc/about/index.vue'); // 关于
const pcAnti = () => import('@/views/pc/anti/index.vue'); // 防伪
const hnfymfsPage = () => import('@/views/pc/hnfymfsPage/index.vue'); // 华侬丰盈美乳霜
const hyqmrtPage = () => import('@/views/pc/hyqmrtPage/index.vue'); // 花月情美乳贴
const xbnfjhpwPage = () => import('@/views/pc/xbnfjhpwPage/index.vue'); // 胸部嫩肤精华喷雾
const crmfymrjyPage = () => import('@/views/pc/crmfymrjyPage/index.vue'); // 萃然美丰韵美乳精油
const ywhsyfgPage = () => import('@/views/pc/ywhsyfgPage/index.vue'); // 燕窝红参御丰膏
const yfgPage = () => import('@/views/pc/yfgPage/index.vue'); // 御丰膏
const jydbywPange = () => import('@/views/pc/jydbywPange/index.vue'); // 胶原蛋白燕窝
const ywtxdbzwPage = () => import('@/views/pc/ywtxdbzwPage/index.vue'); // 葛根燕窝弹性蛋白果味饮品
const sxywPage = () => import('@/views/pc/sxywPage/index.vue'); // 锁鲜燕窝
const videoPage = () => import('@/views/pc/brand/index.vue'); // 品牌资讯 -> 热点视频
const storyPage = () => import('@/views/pc/brand/index.vue'); // 品牌资讯 -> 故事
const honorPage = () => import('@/views/pc/brand/index.vue'); // 品牌资讯 -> 荣誉
const aptitudePage = () => import('@/views/pc/brand/index.vue'); // 品牌资讯 -> 资质
const benefitPage = () => import('@/views/pc/brand/index.vue'); // 品牌资讯 -> 公益
export default [
  {
    path: '/pc/home',
    component: pcHome,
    name: 'pcHome'
  },
  {
    path: '/pc/about',
    component: pcAbout,
    name: 'pcAbout'
  },
  {
    path: '/pc/anti',
    component: pcAnti,
    name: 'pcAnti'
  },
  {
    path: '/pc/hnfymfsPage',
    component: hnfymfsPage,
    name: 'hnfymfsPage'
  },
  {
    path: '/pc/hyqmrtPage',
    component: hyqmrtPage,
    name: 'hyqmrtPage'
  },
  {
    path: '/pc/xbnfjhpwPage',
    component: xbnfjhpwPage,
    name: 'xbnfjhpwPage'
  },
  {
    path: '/pc/crmfymrjyPage',
    component: crmfymrjyPage,
    name: 'crmfymrjyPage'
  },
  {
    path: '/pc/ywhsyfgPage',
    component: ywhsyfgPage,
    name: 'ywhsyfgPage'
  },
  {
    path: '/pc/yfgPage',
    component: yfgPage,
    name: 'yfgPage'
  },
  {
    path: '/pc/jydbywPange',
    component: jydbywPange,
    name: 'jydbywPange'
  },
  {
    path: '/pc/ywtxdbzwPage',
    component: ywtxdbzwPage,
    name: 'ywtxdbzwPage'
  },
  {
    path: '/pc/sxywPage',
    component: sxywPage,
    name: 'sxywPage'
  },
  {
    path: '/pc/videoPage',
    component: videoPage,
    name: 'videoPage'
  },
  {
    path: '/pc/storyPage',
    component: storyPage,
    name: 'storyPage'
  },
  {
    path: '/pc/honorPage',
    component: honorPage,
    name: 'honorPage'
  },
  {
    path: '/pc/aptitudePage',
    component: aptitudePage,
    name: 'aptitudePage'
  },
  {
    path: '/pc/benefitPage',
    component: benefitPage,
    name: 'benefitPage'
  }
];
