<template>
  <div></div>
</template>
<script>
export default {
  created() {
    this.checkDeviceType();
  },
  methods: {
    //判断用户访问环境
    checkDeviceType() {
      const userAgent = window.navigator.userAgent;
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      this.isPC = !isMobile;
      if (isMobile) {
        this.$router.push({
          path: '/mobile/home'
        });
      } else {
        this.$router.push({
          path: '/pc/home'
        });
      }
    }
  }
};
</script>
