const state = {
  showNav: false
};
const mutations = {
  SET_NAV_BOX(state, data) {
    state.showNav = data;
  }
};
const actions = {
  setNavBox({ commit }, flag) {
    commit('SET_NAV_BOX', flag);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
