const mobileHome = () => import('@/views/mobile/home/index.vue'); // 首页
const antiFake = () => import('@/views/mobile/anti/index.vue'); // 防伪鉴别
const mHnfymfsPage = () => import('@/views/mobile/hnfymfsPage/index.vue'); //华侬丰盈美乳霜
const mHyqmrtPage = () => import('@/views/mobile/hyqmrtPage/index.vue'); //花月情美乳贴
const mXbnfjhpwPage = () => import('@/views/mobile/xbnfjhpwPage/index.vue'); //胸部嫩肤精华喷雾
const mCrmfymrjyPage = () => import('@/views/mobile/crmfymrjyPage/index.vue'); //萃然美丰韵美乳精油
const mYwhsyfgPage = () => import('@/views/mobile/ywhsyfgPage/index.vue'); //燕窝红参御丰膏
const mYfgPage = () => import('@/views/mobile/yfgPage/index.vue'); //御丰膏
const mJydbywPange = () => import('@/views/mobile/jydbywPange/index.vue'); //胶原蛋白燕窝饮品
const mYwtxdbzwPage = () => import('@/views/mobile/ywtxdbzwPage/index.vue'); //葛根燕窝弹性蛋白果味饮品
const mSxywPage = () => import('@/views/mobile/sxywPage/index.vue'); //锁鲜燕窝
const mStoryPage = () => import('@/views/mobile/storyPage/index.vue'); // 品牌资讯 -> 故事
const mVideoPage = () => import('@/views/mobile/videoPage/index.vue'); // 品牌资讯 -> 视频
const mBenefitPage = () => import('@/views/mobile/benefitPage/index.vue'); // 品牌资讯 -> 公益课堂
export default [
  {
    path: '/mobile/home',
    component: mobileHome,
    name: 'mobileHome'
  },
  {
    path: '/mobile/anti',
    component: antiFake,
    name: 'antiFake'
  },
  {
    path: '/mobile/hnfymfsPage',
    component: mHnfymfsPage,
    name: 'mHnfymfsPage'
  },
  {
    path: '/mobile/hyqmrtPage',
    component: mHyqmrtPage,
    name: 'mHyqmrtPage'
  },
  {
    path: '/mobile/xbnfjhpwPage',
    component: mXbnfjhpwPage,
    name: 'mXbnfjhpwPage'
  },
  {
    path: '/mobile/crmfymrjyPage',
    component: mCrmfymrjyPage,
    name: 'mCrmfymrjyPage'
  },
  {
    path: '/mobile/ywhsyfgPage',
    component: mYwhsyfgPage,
    name: 'mYwhsyfgPage'
  },
  {
    path: '/mobile/yfgPage',
    component: mYfgPage,
    name: 'mYfgPage'
  },
  {
    path: '/mobile/jydbywPange',
    component: mJydbywPange,
    name: 'mJydbywPange'
  },
  {
    path: '/mobile/ywtxdbzwPage',
    component: mYwtxdbzwPage,
    name: 'mYwtxdbzwPage'
  },
  {
    path: '/mobile/sxywPage',
    component: mSxywPage,
    name: 'mSxywPage'
  },
  {
    path: '/mobile/storyPage',
    component: mStoryPage,
    name: 'mStoryPage'
  },
  {
    path: '/mobile/videoPage',
    component: mVideoPage,
    name: 'mVideoPage'
  },
  {
    path: '/mobile/benefitPage',
    component: mBenefitPage,
    name: 'mBenefitPage'
  }
];
