const state = {
  showActive: false,
  curMenuIndex: 0 //当前菜单的下标
};
const mutations = {
  SET_ACTIVE(state, data) {
    state.showActive = data;
  },
  REMOVE_ACTIVE(state) {
    state.showActive = false;
  },
  SET_CUR_MENU_INDEX(state, index) {
    state.curMenuIndex = index;
  }
};
const actions = {
  setActive({ commit }) {
    commit('SET_ACTIVE', true);
  },
  removeActive({ commit }) {
    commit('REMOVE_ACTIVE');
  },
  setCurMenuIndex({ commit }, index) {
    commit('SET_CUR_MENU_INDEX', index);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
